define("shop/pods/components/elements/image-display/component", ["exports", "@glimmer/component", "@ember/object", "ramda", "shop/utils/nventor", "@ember/service", "@glimmer/tracking"], function (_exports, _component, _object, R, _nventor, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ElementsImageDisplayComponent = (_dec = (0, _service.inject)('website'), (_class = class ElementsImageDisplayComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "images", _descriptor, this);

      _initializerDefineProperty(this, "websiteService", _descriptor2, this);

      _initializerDefineProperty(this, "calculatedHeight", _descriptor3, this);

      _initializerDefineProperty(this, "calculatedWidth", _descriptor4, this);

      _initializerDefineProperty(this, "imgSrcTransformUrl", _descriptor5, this);

      _initializerDefineProperty(this, "imgSrcsetTransformUrl", _descriptor6, this);

      _initializerDefineProperty(this, "container", _descriptor7, this);

      _initializerDefineProperty(this, "ratio", _descriptor8, this);

      _initializerDefineProperty(this, "onLoadImage", _descriptor9, this);

      _initializerDefineProperty(this, "imgSrcsetTransformUrlDesktop", _descriptor10, this);

      _initializerDefineProperty(this, "imgSrcsetTransformUrlTablet", _descriptor11, this);

      this.setup();
    }

    get imageAlt() {
      const imageData = this.args.imageData || {};

      if (imageData !== null && imageData !== void 0 && imageData.alt) {
        return imageData === null || imageData === void 0 ? void 0 : imageData.alt;
      }

      const defaultAlt = this.args.defaultAlt;

      if (defaultAlt) {
        return defaultAlt;
      }

      return 'image';
    }

    get loadingProcedure() {
      var _this$args;

      if (((_this$args = this.args) === null || _this$args === void 0 ? void 0 : _this$args.lazyLoad) === false) {
        return 'eager';
      }

      return 'lazy';
    }

    get decodingProcedure() {
      var _this$args2;

      if (((_this$args2 = this.args) === null || _this$args2 === void 0 ? void 0 : _this$args2.lazyLoad) === false) {
        return 'auto';
      }

      return 'async';
    }

    get isVideo() {
      const url = this.imgSrcTransformUrl || '';

      if (R.includes('/gif2video')(url)) {
        return true;
      }

      return false;
    }

    get srcMp4() {
      var _this$args3;

      const imageData = ((_this$args3 = this.args) === null || _this$args3 === void 0 ? void 0 : _this$args3.imageData) || {};
      return this.images.getGifToVideoSrc(imageData === null || imageData === void 0 ? void 0 : imageData.uuid, 'mp4');
    }

    get srcWebm() {
      var _this$args4;

      const imageData = ((_this$args4 = this.args) === null || _this$args4 === void 0 ? void 0 : _this$args4.imageData) || {};
      return this.images.getGifToVideoSrc(imageData === null || imageData === void 0 ? void 0 : imageData.uuid, 'webm');
    }

    get src() {
      return this.getImgSrc(this.imgSrcTransformUrl);
    }

    get srcset() {
      return this.getImgSrc(this.imgSrcsetTransformUrl);
    }

    get sourceSrcsetDesktop() {
      return this.getImgSrc(this.imgSrcsetTransformUrlDesktop);
    }

    get sourceSrcsetTablet() {
      return this.getImgSrc(this.imgSrcsetTransformUrlTablet);
    }

    getImgSrc(uuid) {
      const defaultTransformations = ['/format/webp/', '/progressive/yes/'];
      const transformationsToAdd = defaultTransformations.reduce((acc, transformation) => {
        const includeTransformation = R.includes(transformation)(uuid);

        if (includeTransformation) {
          return acc;
        }

        acc += `-${transformation}`;
        return acc;
      }, '');
      const url = `https://ucarecdn.com/${uuid}`;

      if (!transformationsToAdd) {
        return url;
      }

      return `${url}${transformationsToAdd}`;
    } // @action
    // _getBackgroundImageUrl () {
    //   const imageData = this.args?.imageData
    //   this.calculatedWidth = this.images.getImgWidth(this.args.width || 300)
    //   const ratio = this.args?.ratio ||'oneByOne'
    //   const resizeMethod = this.args?.resizeMethod
    //   const position = this.args?.imagePosition
    //   const imgHeight = imageData?.imageInfo?.height
    //   const imgWidth = imageData?.imageInfo?.width
    //   const uuid = imageData?.uuid
    //   const mimeType =  imageData?.mimeType
    //   const autoResizeToRatio = this.args?.autoResizeToRatio
    //   this.calculatedHeight = this.images.getCalculatedHeight(
    //     {
    //       width: this.calculatedWidth,
    //       ratio
    //     }
    //   )
    //   return this.images.getImgSrcTransformUrl(
    //     {
    //       height: this.calculatedHeight,
    //       width :this.calculatedWidth,
    //       ratio,
    //       uuid,
    //       mimeType,
    //       imgHeight,
    //       imgWidth,
    //       autoResizeToRatio,
    //       resizeMethod,
    //       position
    //     }
    //   )
    // }


    setup({
      dynamicIncrementImageHeight = 0
    } = {}) {
      var _this$args5, _this$args6, _this$args7, _imageData$imageInfo, _imageData$imageInfo2, _this$args8, _this$websiteService, _this$websiteService$, _this$websiteService$2, _this$websiteService$3;

      const imageData = (_this$args5 = this.args) === null || _this$args5 === void 0 ? void 0 : _this$args5.imageData;
      this.calculatedWidth = this.images.getImgWidth(this.args.width || 300);
      const ratio = this.ratio;
      const resizeMethod = (_this$args6 = this.args) === null || _this$args6 === void 0 ? void 0 : _this$args6.resizeMethod;
      const position = (_this$args7 = this.args) === null || _this$args7 === void 0 ? void 0 : _this$args7.imagePosition;
      const imgHeight = imageData === null || imageData === void 0 ? void 0 : (_imageData$imageInfo = imageData.imageInfo) === null || _imageData$imageInfo === void 0 ? void 0 : _imageData$imageInfo.height;
      const imgWidth = imageData === null || imageData === void 0 ? void 0 : (_imageData$imageInfo2 = imageData.imageInfo) === null || _imageData$imageInfo2 === void 0 ? void 0 : _imageData$imageInfo2.width;
      const uuid = imageData === null || imageData === void 0 ? void 0 : imageData.uuid;
      const mimeType = imageData === null || imageData === void 0 ? void 0 : imageData.mimeType;
      const autoResizeToRatio = (_this$args8 = this.args) === null || _this$args8 === void 0 ? void 0 : _this$args8.autoResizeToRatio; // if browserWidth < this.calculatedWidth, set this.calculatedWidth = browserWidth

      let browserWidth = window.innerWidth || document.body.clientWidth || document.documentElement.clientWidth;

      if (browserWidth > 450) {
        browserWidth = 450;
      }

      if (this.calculatedWidth > browserWidth) {
        this.calculatedWidth = browserWidth;
      }

      const transformUrlData = {
        height: this.calculatedHeight,
        width: this.calculatedWidth,
        ratio,
        uuid,
        mimeType,
        imgHeight,
        imgWidth,
        autoResizeToRatio,
        resizeMethod,
        position
      };
      let betterImageMultiplier = 1;

      if (((_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : (_this$websiteService$ = _this$websiteService.website) === null || _this$websiteService$ === void 0 ? void 0 : (_this$websiteService$2 = _this$websiteService$.shop) === null || _this$websiteService$2 === void 0 ? void 0 : (_this$websiteService$3 = _this$websiteService$2._data) === null || _this$websiteService$3 === void 0 ? void 0 : _this$websiteService$3.betterImages) === true) {
        betterImageMultiplier = 1.5;
      }

      betterImageMultiplier += dynamicIncrementImageHeight;
      this.calculatedHeight = this.images.getCalculatedHeight(transformUrlData); // Mobile

      this.imgSrcTransformUrl = this.images.getImgSrcTransformUrl(transformUrlData); // Mobile Retina

      transformUrlData.width = Math.round(this.calculatedWidth * 1.25 * betterImageMultiplier);
      this.imgSrcsetTransformUrl = this.images.getImgSrcsetTransformUrl(transformUrlData); // desktop

      transformUrlData.width = Math.round(this.calculatedWidth * 1.5 * betterImageMultiplier);
      this.imgSrcsetTransformUrlDesktop = this.images.getImgSrcsetTransformUrl(transformUrlData); // tablet

      transformUrlData.width = Math.round(this.calculatedWidth * 1.25 * betterImageMultiplier);
      this.imgSrcsetTransformUrlTablet = this.images.getImgSrcsetTransformUrl(transformUrlData);
    }
    /**
     * @function setupVideoAutoPlay
     * @description Sets up autoplay, loop, mute, default muted, webkit, and playsinline properties of a video element.
     *
     * @param {HTMLVideoElement} video - The video element to set up autoplay.
     *
     * @return {void}
     */


    setupVideoAutoPlay(video) {
      if (video.autoplay !== true) {
        video.autoplay = true;
      }

      if (video.loop !== true) {
        video.loop = true;
      }

      if (video.muted !== true) {
        video.muted = true;
      }

      if (video.defaultMuted !== true) {
        video.defaultMuted = true;
      }

      if (video.playsInline !== true) {
        video.playsInline = true;
      }

      const hasOnCanPlayDataKey = 'data-has-oncanplay';
      const hasOnCanplay = video.getAttribute(hasOnCanPlayDataKey) === 'true';

      if (!hasOnCanplay) {
        video.oncanplay = () => {
          video.play().catch(console.error);
        };

        video.setAttribute(hasOnCanPlayDataKey, true);
      }

      video.addEventListener('loadeddata', () => {
        if (!this.container) {
          return;
        }

        this.removeStyle(this.container);
      });
    }

    updateStyle(url, container) {
      try {
        if (!container) {
          return;
        }

        this.container = container; // const backgroundImageUrl = this._getBackgroundImageUrl()
        // if (!img) {
        //   return
        // }

        container.style.minWidth = `${this.calculatedWidth}px`;
        container.style.minHeight = `${this.calculatedHeight}px`;
        const img = container.querySelector('img');

        if (!img) {
          this.removeStyle(container);
        } // website-container banner-img


        let backgroundColor = 'none';
        const argsBackgroundColor = this.args.backgroundColor;
        const isBackgroundColorArray = R.is(Array)(argsBackgroundColor);
        const isBackgroundColorString = R.is(String)(argsBackgroundColor);
        const isPNG = R.pipe(R.pathOr('', ['args', 'imageData', 'mimeType']), R.toLower, R.includes('png'))(this);

        if (isPNG) {
          backgroundColor = 'transparent';
        } else if (isBackgroundColorArray) {
          backgroundColor = `rgb(${this.args.backgroundColor})`;
        } else if (isBackgroundColorString) {
          backgroundColor = this.args.backgroundColor;
        } // display background-color only while no defaultImage


        container.style.backgroundColor = backgroundColor;

        if (R.includes('/gif2video')(url)) {
          return;
        }

        if (R.includes('/-/')(url)) {
          // already transformed
          container.style.backgroundImage += `, url(${url}/)`;
        } // container.style.backgroundRepeat = 'no-repeat'


        if (img) {
          img.style.maxHeight = '100%';
          img.style.width = '100%';
        } // img.style.minWidth = `${this.calculatedWidth}px`


        if (this.args.autoResizeToRatio) {
          if (!this.args.imageData.uuid) {
            container.style.width = `${this.calculatedWidth}px`;
          } else {
            container.style.width = '100%';
          }

          if (!this.isVideo && !img.src) {
            container.style.height = `${this.calculatedHeight}px`;
          }

          if (img) {
            img.style.visibility = 'hidden';
            img.style.width = '100%';
            img.style.height = '100%';
          }
        }

        const tagsInGrids = container.getElementsByClassName('tag-in-grids') || [];
        R.forEach(tagContainer => {
          tagContainer.style.visibility = 'hidden';
        })(tagsInGrids);

        if (img) {
          if (img.complete) {
            this.removeStyle(container);
            R.forEach(tagContainer => {
              tagContainer.style.visibility = 'visible';
            })(tagsInGrids);
          } else {
            this.onLoadImage = event => {
              this.removeStyle(container, event);
              R.forEach(tagContainer => {
                tagContainer.style.visibility = 'visible';
              })(tagsInGrids);
              img.removeEventListener('load', this.onLoadImage);
            };

            img.addEventListener('load', this.onLoadImage);
          }
        }
      } catch (e) {
        console.error(e);
      }
    }

    removeStyle(container) {
      if (!container) {
        return;
      }

      let browserWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (browserWidth > 450) {
        browserWidth = 450;
      }

      const img = container.querySelector('img');

      if (img) {
        var _this$args9, _this$args9$imageData, _this$args10;

        if ((_this$args9 = this.args) !== null && _this$args9 !== void 0 && (_this$args9$imageData = _this$args9.imageData) !== null && _this$args9$imageData !== void 0 && _this$args9$imageData.uuid) {
          img.style.visibility = 'visible';
        } else {
          img.style.visibility = 'hidden';
        }

        if ((_this$args10 = this.args) !== null && _this$args10 !== void 0 && _this$args10.isItemCard) {
          if (browserWidth / 2 < this.calculatedWidth) {
            img.style.minWidth = '130px';
          }
        }
      } // if (img?.style?.minWidth !== undefined) {
      //   img.style.removeProperty('minWidth')
      // }


      container.style.minHeight = '0px';
      container.style.minWidth = '0px';
    }

    select() {
      if (this.args.onSelect) {
        this.args.onSelect();
      }

      const currentPathname = window.location.pathname;
      const isShopDetailRoute = R.includes('detail')(currentPathname) && R.includes('shop')(currentPathname);

      if (isShopDetailRoute && this.hasGoToTop) {
        _nventor.default.goToTop();
      }
    }

    updatePictureStyle(picture) {
      if (!this.args.height) {
        return;
      }

      picture.style.height = `${this.args.height}px`;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "images", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "websiteService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "calculatedHeight", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calculatedWidth", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "imgSrcTransformUrl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "imgSrcsetTransformUrl", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "container", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "ratio", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      var _this$args11;

      return _nventor.default.defaultWhen(_nventor.default.isNilOrEmpty, (_this$args11 = this.args) === null || _this$args11 === void 0 ? void 0 : _this$args11.ratio, '');
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onLoadImage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "imgSrcsetTransformUrlDesktop", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "imgSrcsetTransformUrlTablet", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getImgSrc", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getImgSrc"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupVideoAutoPlay", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupVideoAutoPlay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStyle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateStyle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeStyle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeStyle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePictureStyle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updatePictureStyle"), _class.prototype)), _class));
  _exports.default = ElementsImageDisplayComponent;
});