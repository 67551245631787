define("shop/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "1000_points": "+1000 points",
    "100_points": "+100 points",
    "500_points": "+500 points",
    "50_points": "+50 points",
    "ATM_BOT": "Bank of Taiwan（台灣銀行） virtual account",
    "ATM_CATHAY": "Cathay United（國泰世華銀行） virtual account (unavailable)",
    "ATM_CHINATRUST": "CTBC Bank（中國信託） virtual account",
    "ATM_ESUN": "E.SUN Bank（玉山銀行） virtual account (unavailable)",
    "ATM_FIRST": "First Bank（第一銀行） virtual account",
    "ATM_FUBON": "Taipei Fubon Bank（台北富邦） virtual account (unavailable)",
    "ATM_LAND": "Land Bamk of Taiwan（土地銀行） virtual account",
    "ATM_TACHONG": "Tachung Bank（大眾銀行） virtual account (had been merged to Yuanta Bank（元大銀行） since 2018)",
    "ATM_TAISHIN": "Taishin Bank（台新銀行） virtual account",
    "Family Mart": "Family Mart",
    "MM/YY": "MM/YY",
    "OTHER": "OTHER",
    "SHIPPING_FEE": "Shipping fee",
    "Seven Eleven": "7-11",
    "Taiwan": "Taiwan",
    "TapPay is out of service.": "TapPay is out of service",
    "Validation Error": "Invalid",
    "account": "Account",
    "accumulated member points": "Accumulated member points",
    "add": "Add to cart",
    "add another": "Add another",
    "add another set": "Add another set",
    "add more": "Add More",
    "add to cart": "Add to cart",
    "add to cart again": "Add another to cart",
    "added": "Added",
    "address": "Address",
    "afer noon": "After Noon",
    "after noon": "After Noon",
    "afterNoon": "After Noon",
    "agree and checkout": "Agree and Checkout",
    "all": "All",
    "all products": "All products",
    "already a member, please login": "Already a member, please login",
    "always use tax number on checkout": "Always use tax number on checkout",
    "amex": "AMEX",
    "an email will be sent to you shortly if an account exists for this email address": "An email will be sent to you shortly",
    "an error has occurred on your previous transaction, please contact us. or retry with the checkout, but may lead to duplicate orders": "An error has occurred on your previous transaction, please contact us. Or retry with the checkout, but may lead to duplicate orders.",
    "an error occurred with your credit card processing. please try entering your credit card again manually without autocomplete": "An error occurred with your credit card processing, please try entering your credit card again manually without autocomplete.",
    "anytime": "Anytime",
    "applied bulk discounts": "Applied Discounts",
    "apply all bulk discount when buy items": "{discount} discount when items quantity {criteria} {criteriaValue}",
    "apply all bulk discount when items value": "{discount} discount when items value {criteria} {criteriaValue}",
    "apply promo code": "Apply promo code",
    "are you sure you want to clear cart?": "Are you sure you want to clear cart?",
    "are you sure you want to delete stored credit card": "Are you sure you want to delete stored credit card?",
    "are you sure you want to remove from cart?": "Are you sure you want to remove from cart?",
    "area": "District",
    "atm_attentionRequired": "Pending manual processing",
    "atm_overpaid": "Virtual account amount overpaid",
    "atm_underpaid": "Virtual account amount underpaid",
    "automation_error_message": "Something wrong with the selected items",
    "available promo codes": "Available Promo Codes",
    "available qty": "Available quantity",
    "awaitingCreditCardPayment": "Awaiting payment",
    "awaitingPayment": "Awaiting payment",
    "back": "Back",
    "back to checkout": "Back to checkout page",
    "back to store": "Back to store",
    "bank processing error": "Bank processing error",
    "bankCode_004": "臺灣銀行 Bank of Taiwan (004)",
    "bankCode_005": "土地銀行 Land Bank of Taiwan (005)",
    "bankCode_006": "合作金庫銀行 Taiwan Cooperative Bank (006)",
    "bankCode_007": "第一銀行 First Bank (007)",
    "bankCode_008": "華南銀行 Hua Nan Bank (008)",
    "bankCode_009": "彰化銀行 Chang Hwa Bank (009)",
    "bankCode_011": "上海商業儲蓄銀行 Shanghai Commercial and Savings Bank (011)",
    "bankCode_012": "台北富邦銀行 Fubon Financial (012)",
    "bankCode_013": "國泰世華銀行 Cathay United Bank (013)",
    "bankCode_016": "高雄銀行 Bank of Kaohsiung (016)",
    "bankCode_017": "兆豐國際商業銀行 Mega International Commercial Bank (017)",
    "bankCode_018": "全國農業金庫 Agricultural Bank of Taiwan (018)",
    "bankCode_020": "瑞穗銀行 Mizuho Bank Taiwan (020)",
    "bankCode_021": "銀行 Citibank Taiwan (021)",
    "bankCode_022": "美國銀行 Bank of America (022)",
    "bankCode_025": "首都銀行 Metrobank (025)",
    "bankCode_039": "銀行 ANZ Taiwan (039)",
    "bankCode_050": "臺灣企銀 Taiwan Business Bank (050)",
    "bankCode_052": "渣打商業銀行 Standard Chartered (052)",
    "bankCode_053": "台中商業銀行 Taichung Bank (053)",
    "bankCode_054": "京城商銀 King’s Town Bank (054)",
    "bankCode_073": "德意志銀行 Deutsche Bank (073)",
    "bankCode_075": "東亞銀行 Bank of East Asia (075)",
    "bankCode_081": "銀行 HSBC Taiwan (081)",
    "bankCode_082": "法國巴黎銀行 BNP Paribas Taiwan (082)",
    "bankCode_086": "法國東方匯理銀行 Crédit Agricole CIB (086)",
    "bankCode_101": "瑞興銀行 Taipei Star Bank (101)",
    "bankCode_102": "華泰銀行 Hwatai Bank (102)",
    "bankCode_103": "臺灣新光商業銀行 Shin Kong Bank (103)",
    "bankCode_108": "陽信銀行 Sunny Bank (108)",
    "bankCode_118": "板信商業銀行 Bank of Panshin (118)",
    "bankCode_147": "三信商業銀行 COTA Commercial Bank (147)",
    "bankCode_700": "中華郵政 Chunghwa Post (Post Office) (700)",
    "bankCode_803": "聯邦銀行 Union Bank of Taiwan (803)",
    "bankCode_805": "遠東國際商業銀行 Far Eastern International Bank (805)",
    "bankCode_806": "元大銀行 Yuanta Bank (806)",
    "bankCode_807": "永豐銀行 Bank SinoPac (807)",
    "bankCode_808": "玉山銀行 E.Sun Bank (808)",
    "bankCode_809": "凱基銀行 KGI Bank (809)",
    "bankCode_810": "銀行 DBS Taiwan (810)",
    "bankCode_812": "台新銀行 Taishin International Bank (812)",
    "bankCode_815": "日盛國際商業銀行 JihSun Bank (815)",
    "bankCode_816": "安泰商業銀行 EnTie Bank (816)",
    "bankCode_822": "中國信託 Chinatrust Commercial Bank (822)",
    "before noon": "Before Noon",
    "beforeNoon": "Before Noon",
    "billing telephone": "Billing Telephone",
    "birthday": "Birthday",
    "birthday can only be set once": "Birthday can only be set once!",
    "blog": "Blog",
    "blogs": "Blogs",
    "bulk discount": "Discount",
    "bulk discount applied": "Discount",
    "bulk discount applied to": "{bulkDiscountName} applied to {itemsName}",
    "bulk discount item qty description": "Items quantity {criteria} {criteriaValue} discount {discount}",
    "bulk discount item value description": "Items value {criteria} {criteriaValue} discount {discount}",
    "bulk discount total": "Total Discount",
    "bulk discount when buy items": "{discount} discount when items quantity {criteria} {criteriaValue}",
    "bulk discount when items value": "{discount} discount when items value {criteria} {criteriaValue}",
    "buy": "Buy",
    "buy now": "Buy Now",
    "by": "Provided by {company}",
    "campaign expired": "Campaign has expired",
    "cancel": "Cancel",
    "cancel redirect": "Cancel redirect",
    "cancelled": "Cancelled",
    "cannot apply referral code": "Cannot apply referral code, please try again",
    "cannot refer to someone that already refer you": "You are unable to refer to someone who has already referred you",
    "cannot reset password": "Cannot reset your password because you have logged in via Facebook/Line",
    "cannot send promo code": "Cannot send promo code",
    "cannot send referral code": "Cannot send referral code",
    "cannot update subscription": "An error has occurred, cannot update subscription status, please check the link or try again later",
    "card": "Credit Card",
    "cart": "Cart",
    "cash": "Cash on delivery",
    "cash payment fee": "Payment fee",
    "cashBeforeDelivery": "Bank transfer",
    "categories": "Categories",
    "cc_approved": "Approved",
    "cc_error_contact_bank": "Authentication has failed, please use an alternate credit card",
    "cc_error_declined": "Credit card has been declined, please use an alternate credit card",
    "cc_error_expiration_month": "Invalid expiry date",
    "cc_error_expiration_year": "Invalid expiry date",
    "cc_error_expired": "Credit card has expired, please use an alternate credit card",
    "cc_error_holder": "Invalid",
    "cc_error_invalid_card": "Invalid",
    "cc_error_number": "Invalid",
    "cc_error_security_code": "Invalid security code",
    "cc_error_use_existing_card": "Please choose existing credit cards, or try to use an alternate credit card",
    "cc_refundRequested": "Refund Requested",
    "cc_refunded": "Refunded",
    "cc_scheduledPayment": "Credit Card Payment Scheduled For",
    "change": "Change",
    "check order status": "Check order status",
    "checkout": "Checkout",
    "checkout as guest": "Checkout as guest",
    "checkout now": "Checkout now",
    "checkout_details": "Details",
    "checkout_payment": "Payment",
    "checkout_summary": "Summary",
    "checkout_unsuccessful_msg": "An error has occurred, please try again!",
    "choose": "Choose",
    "choose customizations": "Choose Customizations",
    "choose expected pickup date": "Choose Preferred Pickup Date",
    "choose expectedArrivalDate": "Choose Preferred Arrival Date",
    "choose expectedDispatchDate": "Choose Preferred Dispatch Date",
    "choose options": "Choose options",
    "choose qty": "Choose quantity",
    "chosen qty": "Chosen quantity",
    "city": "City",
    "claim promo code": "Claim Promo Code",
    "clear": "Clear",
    "clear all": "Clear all",
    "clear cart": "Clear cart",
    "clear promo code": "Clear promo code",
    "clear search conditions": "Clear Search Conditions",
    "close": "Close",
    "company": "Company",
    "companyName": "Company name",
    "completed formStep": "Completed",
    "confirm": "Confirm",
    "confirmPassword": "Confirm password",
    "connection error": "Connection error, please try again later",
    "contact us": "Contact us",
    "contains an invalid value": "Invalid",
    "contains duplicate value": "Invalid, contains duplicate value",
    "contains recurring products text": "Cart Contains Recurring Orders, Do You Agree the Terms and Conditions? (Click \"Show More\" to Read the Terms and Conditions)",
    "continue with checkout": "Continue with checkout",
    "convenience store brand": "Convenience store brand",
    "convenience store more": "There is a limit on the size and weight of the delivery for convenience store pick-up. If the limit is exceeded, we will notify you and assist in modifying the delivery method.",
    "convenience store pick up": "Convenience store pick up",
    "copied": "Copied",
    "copied, please wait": "Copied, please wait...",
    "copy": "Copy",
    "copy and track waybill": "Copy and Track Waybill",
    "country": "Country",
    "credit card error": "Credit Card Error",
    "credit card was entered incorrectly": "Credit card was entered incorrectly. Please check expiry date and credit card details or use a different card.",
    "creditCard": "Credit Card (Visa, Mastercard & JCB)",
    "creditCardExpiryDate": "Crefit card expiration date (MM/YY)",
    "creditCardHolder": "Credit card holder",
    "creditCardNumber": "Credit card number",
    "creditCardOrCreditCardWithInstallments": "Credit Card / Credit Card With Installments",
    "creditCardSecureCode": "Credit card secure code",
    "credit_card_bind_required": "Recurring items require credit card to be remembered",
    "credit_card_bind_test_transaction_msg": "When remembering the credit card, there will be a $1 test transaction that is immediately refunded",
    "credit_card_declined": "Credit card has been declined, please use an alternate credit card",
    "credit_card_expired": "Credit card has expired, please use an alternate credit card",
    "credit_card_failed_possible_reasons": "Possible reasons for failure",
    "current_lang": "English(US)",
    "customer additional info": "Additional info",
    "customer info": "Customer info",
    "customer service": "Customer service",
    "customizations": "Customizations",
    "date end": "End date",
    "date start": "Start date",
    "dateNewToOld": "Date from new to old",
    "dateOldToNew": "Date from old to new",
    "day_closed": "Closed",
    "days": "days",
    "delete": "Delete",
    "delivery info": "Delivery info",
    "delivery time": "Delivery Time",
    "deliveryTime": "Delivery Time",
    "description": "Description",
    "did you unsubscribe by accident": "Did you unsubscribe by accident?",
    "discount": "Discount",
    "discount when buy items": "discount when buy items",
    "discount when items value": "discount when buy items",
    "dispatched": "Dispatched",
    "dispatched date": "Dispatched date",
    "district": "District",
    "does not contain required value": "Invalid",
    "edit": "Edit",
    "email": "Email",
    "email already verified": "Email already verified",
    "email unverified": "Email unverified",
    "email verification required": "Email verification is required",
    "email_verification_code_invalid": "Email verification code is invalid. Please login and click on resend verification email",
    "email_verification_required_message": "Your email has not yet been verified, we have sent you a verification email. Please click on the link in the email to complete your signup. Please check your spam folder if you do not see the email in your inbox.",
    "email_verification_sent": "Verification email has been sent",
    "email_verification_success": "Email verification succeeded",
    "enter again": "Try again",
    "enter_reward_points": "Enter reward points",
    "equals": "Equals",
    "error occurred": "An error has occurred, please try again later",
    "error occurred, please contact us": "An error has occurred, please contact us",
    "error occurred, please refresh page": "An error has occurred, please refresh the page",
    "error_address": "Address",
    "error_alternateAddressData": "address",
    "error_alternateAddressData.name": "name",
    "error_alternateAddressData.telephone": "telephone",
    "error_ccExpiry": "Credit card expiry",
    "error_ccNumber": "Credit card number",
    "error_ccSecureCode": "Invalid credit Card (CCV)",
    "error_companyName": "Company name",
    "error_country": "Country",
    "error_countryCode": "Country",
    "error_district": "District",
    "error_email": "Email",
    "error_name": "Name",
    "error_notes": "Notes",
    "error_password": "Invalid password",
    "error_paymentLastFiveDigits": "Payment last five digits",
    "error_postCode": "Post code",
    "error_region": "Region",
    "error_street": "Street",
    "error_surchargeId": "Surcharge",
    "error_taxNumber": "Tax number",
    "error_telephone": "Telephone",
    "error_twEInvoiceCarrierId": "Invalid E-Invoice",
    "error_unverifiedEmail": "Unverified email",
    "error_useDefaultTaxNumber": "Tax number",
    "expected pickup date": "Preferred Pickup Date",
    "expectedArrivalDate": "Preferred Arrival Date",
    "expectedDispatchDate": "Preferred Dispatch Date",
    "expired": "Expired",
    "expiring reward points": "Expiring Reward Points",
    "expiry date": "Reward Expiry Date",
    "extras": "Extras",
    "facebook customer service": "Facebook Customer Service",
    "familyMart": "Family Mart",
    "faqs": "FAQs",
    "female": "Female",
    "fetch user convenience store data": "From The Latest Data",
    "filters": "Filters",
    "flow_done": "Done! Thank You!",
    "flow_invalid_input": "Invalid input",
    "forgot password": "Forgot password?",
    "friday": "Fri",
    "game_scratch_card_sorry": "Sorry...",
    "game_scratch_card_title": "Scratch Me",
    "gender": "Gender",
    "generating": "Generating",
    "generating promo code": "Generating Promo Code...",
    "go to home page": "Go to home page",
    "greaterThan": "Greater than",
    "greaterThanOrEqualTo": "Greater than or equal to",
    "have already referred": "Have already referred",
    "hide": "Hide",
    "home": "Home",
    "home delivery": "Home delivery",
    "home page": "Home page",
    "homeDelivery": "Home delivery",
    "i agree to terms and conditions": "I agree to terms and conditions",
    "i have a promo code": "I have a promo code",
    "i have a referral code": "I have a referral code",
    "if you have any concerns, please contact us": "If you have any concerns, please contact us",
    "inactive": "Sold out",
    "incorrect": "Invalid",
    "incorrect array order": "Invalid",
    "incorrect array order and/or incorrect number of items": "Invalid",
    "incorrect byte": "Invalid",
    "incorrect bytes": "Invalid",
    "incorrect credit card details": "Incorrect Credit Card Details",
    "incorrect date": "Incorrect date",
    "incorrect date format": "Incorrect date format",
    "incorrect date range": "Incorrect date range",
    "incorrect decimal places": "Invalid, incorrect decimal places",
    "incorrect length": "Invalid",
    "incorrect number": "Invalid, must be number",
    "incorrect number of items": "Invalid",
    "incorrect uri scheme": "Invalid, incorrect URI scheme",
    "incorrect value": "Invalid",
    "info": "Info",
    "input password to preview page": "Password",
    "installment periods": "Installment Periods",
    "installmentPayment": "Installments",
    "installments": "Installments",
    "insufficient inventory qty": "Insufficient inventory quantity",
    "invalid": "Invalid",
    "invalid access code": "invalid access code",
    "invalid amount of reward points": "Invalid amount of reward points",
    "invalid credit card": "Invalid Credit Card",
    "invalid credit card or bank processing error": "Invalid credit card or bank processing error",
    "invalid login credentials": "Invalid email or password",
    "invalid scheduled payment credit card": "There is some problem with your credit card with last number: {cardNumber}",
    "is not allowed": "Invalid",
    "item": "Item",
    "item is not supported by shipping zones": "Item is not supported by shipping zones",
    "jcb": "JCB",
    "keep me up to date on news and exclusive offers": "Keep me up to date on news and exclusive offers",
    "language_en": "English",
    "language_tw": "繁體中文",
    "last 1 month": "Last 1 month",
    "last 1 year": "Last 1 year",
    "last 3 months": "Last 3 months",
    "last 5 digits of your account": "Last 5 digits of your account",
    "last 6 months": "Last 6 months",
    "leave a message": "Leave a message",
    "limited qty per checkout": "Limited quantity per checkout: {total}",
    "limited qty per person": "Limited quantity per person",
    "limited_campaign": "Limited Campaign",
    "limited_campaign_ended": "Limited Campaign Ended",
    "limited_campaign_until": "until {dateEndZ}",
    "line customer service": "Line Customer Service",
    "line pay": "Line Pay",
    "linePay": "Line Pay",
    "loading": "Loading",
    "login": "Login",
    "login-remind-message": "Sign up once, enjoy easy checkout across all websites by {BigordrLogo}, allowing a seamless shopping experience and saving you time.",
    "loginByBigOrdr": "Login by BigOrdr",
    "loginByFB": "Login by Facebook",
    "loginByLine": "Login by Line",
    "logout": "Logout",
    "male": "Male",
    "mastercard": "MasterCard",
    "max characters": "Max Characters",
    "maximum reward points that can be applied on this order": "Maximum reward points that can be applied on this order",
    "member country is not supported, please send to different address": "Member's Country Is Not Supported, Please Use Alternate Address or Change The Settings in Member's Page.",
    "member_level": "Member level",
    "message": "Message",
    "minimum order to enable shipping": "Minimum order of {rate} is required to ship to your address",
    "minimum spend to apply reward points": "Minimum spend to apply reward points",
    "minimum total for installment": "Minimum total {amount} required to pay with installments",
    "mobile number": "Mobile number",
    "monday": "Mon",
    "more": "more",
    "multiplied": "Multiplied",
    "must be a bigger number": "Invalid, must be a larger number",
    "must be a boolean": "Invalid",
    "must be a buffer or a string": "Invalid",
    "must be a credit card": "Must be a credit card",
    "must be a float or double": "Invalid, must be a float or double",
    "must be a negative number": "Invalid, must be a negative number",
    "must be a number": "Invalid, must be a number",
    "must be a positive number": "Invalid, must be a positive number",
    "must be a smaller number": "Invalid, must be a smaller number",
    "must be a string": "Invalid, must be a string",
    "must be a valid GUID": "Nust be a valid GUID",
    "must be a valid ISO 8601 date": "Must be a valid ISO 8601 date",
    "must be a valid base64 string": "Must be a valid base64 string",
    "must be a valid date": "Must be a valid date",
    "must be a valid email": "Invalid, must be a valid email",
    "must be a valid hostname": "Must be a valid hostname",
    "must be a valid ip address": "Must be a valid IP address",
    "must be a valid port": "Invalid, must be a valid port",
    "must be a valid relative uri": "Invalid, must be a valid relative URI",
    "must be a valid timestamp or number of milliseconds": "Invalid, must be a valid timestamp or number of milliseconds",
    "must be a valid timestamp or number of second": "Invalid, must be a valid timestamp or number of second",
    "must be a valid uri": "Invalid, must be a valid URI",
    "must be an array": "Invalid",
    "must be an integer": "Invalid, must be an integer",
    "must be unicode normalized": "Must be unicode",
    "must exclude value": "Invalid",
    "must not have leading or trailing whitespace": "Must not have leading or trailing whitespace",
    "must only contain alpha-numeric and underscore characters": "Invalid, must only contain alpha-numeric and underscore characters",
    "must only contain alpha-numeric characters": "Invalid, must only contain alpha-numeric characters",
    "must only contain hexadecimal characters": "Must only contain hexadecimal characters",
    "must only contain uppercase characters": "Must only contain uppercase characters",
    "name": "Name",
    "new password": "New password (at least 8 characters)",
    "newestFirst": "上架日期由新到舊",
    "news": "News",
    "next page": "Next page",
    "next step": "Next",
    "night": "Night",
    "no": "No",
    "no email or ref value": "No email or ref value",
    "no matched results": "No Matched Results",
    "no more choices, please decrease other item qtys": "No more choices, please decrease other item quantities",
    "no order found": "No order found",
    "no product found": "No product found.",
    "no products found": "No products found",
    "none": "none",
    "noon": "Noon",
    "not email": "Email format error",
    "not matching any of the allowed alternatives": "Invalid",
    "not numeric": "Invalid",
    "notes": "Notes",
    "notes_eg": "Notes",
    "number of games remaining": "Number of games remaining",
    "ok": "OK",
    "onSale": "On sale",
    "onlyOnSale": "On sale",
    "ooops, page not found": "Ooops, page not found",
    "oops sorry": "Oops, Sorry!",
    "open hours": "Open hours",
    "opening points": "Opening points",
    "optional": "Optional",
    "or": "or",
    "order date": "Order date",
    "order details": "Order details",
    "order history": "Order history",
    "order number": "Order number",
    "order status": "Order status",
    "order was emailed to": "Order has been emailed to",
    "order_messages_sent": "Thank you for your message. We will get back to you shortly.",
    "order_successful_msg": "Thank you! We have received your order.",
    "other": "other",
    "out of stock": "Out of stock",
    "out of stock: removed from cart": "Out of stock: removed from cart",
    "outOfStock": "Out of stock",
    "over limited qty": "Over limited quantity",
    "over limited qty per checkout": "Over limited quantity per checkout",
    "page status": "Status",
    "page_active": "Active",
    "page_inactive": "Inactive",
    "password": "Password",
    "password (at least 8 characters)": "Password (at least 8 characters)",
    "password updated successfully": "Password updated successfully",
    "passwords error": "Passwords error",
    "pay before payment due date": "Payment is scheduled for {date}, are you sure you want to pay now instead?",
    "pay in installments": "Pay in installments",
    "pay now": "Pay now",
    "pay to": "Pay to",
    "pay_by_link": "Complete Payment",
    "payer": "Payer",
    "payment banking details": "If you choose bank transfer as your payment method, please transfer to the count below",
    "payment due date": "Payment Due Date",
    "payment method": "Payment method",
    "payment successful": "Payment successful",
    "paymentMethod_cash": "Cash on delivery",
    "paymentMethod_cashBeforeDelivery": "Bank Transfer",
    "paymentMethod_creditCard": "Credit Card",
    "paymentMethod_free": "Free",
    "paymentMethod_linePay": "Line Pay",
    "paymentMethod_other": "Other",
    "payment_unsuccessful_msg": "Unfortunately we ran into some problems with processing your payment, please verify your credit card and try again later or choose another payment method.",
    "payuni_card_redirect": "You will be redirected to PayUni to complete the payment for {companyName}",
    "pending": "Pending",
    "pending_reward_points": "Pending reward points",
    "pick up method": "Pick up method",
    "pick up store": "Pick up Store",
    "please enter full name": "Please enter full name",
    "please make a selection": "Please make a selection",
    "please search again": "Please search again",
    "please select": "Please select",
    "please wait": "Please wait",
    "point can be used directly": "Reward points can be used directly",
    "point cannot be used directly": "Reward points must be verified before used",
    "points": "{point} points",
    "popular": "Popular items",
    "postCode": "Post Code",
    "pre order": "Pre Order",
    "preOrder": "Pre Order",
    "preferred delivery time": "Preferred delivery time",
    "preparingForDispatch": "Preparing",
    "previous page": "Previous page",
    "previous step": "Previous step",
    "price": "Price",
    "priceAsc": "Price from low to high",
    "priceDesc": "Price from high to low",
    "prices": "Prices",
    "prices_starting_from": "From {price}",
    "prices_starting_from_only_one": "{price}",
    "privacy policy": "Privacy Policy",
    "problems with auto filling credit card details": "Some browsers may have problems with auto filling credit card details. Enter card manually. Try using a different browser, if on mobile, try using the device's native Safari or Chrome browser.",
    "proceed with payment": "Proceed with payment",
    "processing": "Processing",
    "product code": "Code",
    "product info": "Product info",
    "productsArea": "Products area",
    "profile": "Profile",
    "promo code": "Promo code",
    "promo code applied": "We have applied promo code for you",
    "promo code change email warning": "Changing email address may cause promo code to not be applicable",
    "promo code claimed": "Promo Code Claimed",
    "promo code has been sent": "Promo Code has been sent",
    "promo code may be expired or invalid for items in cart": "Promo code may be expired or invalid for items in cart",
    "promo code may be expired, invalid for items in cart or has been used": "Promo code may be expired, invalid for items in cart or has been used",
    "promo codes": "Promo codes",
    "promo_code_applied": "Applied",
    "promo_code_can_be_used_on_sale": "Can be applied with on sale items",
    "promo_code_cannot_be_used_on_sale": "Cannot be applied with on sale items",
    "promo_code_date_start": "Applicable after",
    "promo_code_description": "Description",
    "promo_code_discount": "Discount",
    "promo_code_expiry_date": "Expiry date",
    "promo_code_from": "From",
    "promo_code_minimum_amount": "Minimum order amount",
    "promo_code_not_found": "No Promo Code Found",
    "promo_code_onsale_discount": "On Sale Discount",
    "promo_code_usage": "Usage",
    "promo_code_usage_limit": "Usage Limit",
    "promo_code_usage_limit_per_person": "Usage Limit Per Person",
    "promo_code_valid_from": "Valid From",
    "promo_code_valid_till": "Valid Till",
    "promotion is already over": "Promotion is already over",
    "qty": "Quantity",
    "qty sold": "Quantity sold",
    "re-subscribe": "re-subscribe",
    "receivers address": "Receiver's address",
    "receivers mobile number": "Receiver's mobile number",
    "receivers name": "Receiver's name",
    "recent": "Recent",
    "recipient": "Recipient",
    "recommended": "Recommended",
    "recurring": "Recurring",
    "recurring order": "Recurring Order",
    "recurring orders": "Recurring Orders",
    "redirect to another page": "Redirect to another page",
    "redirecting to another page": "Redirecting to another page",
    "referenced field is not a date": "Reference field is not a date",
    "referenced field is not a number": "Reference field is not a number",
    "referral code": "Referral Code",
    "referral code does not exist": "Referral Code does not exist",
    "referral code has been sent": "Referral Code has been sent",
    "referral code limit reached": "Referral code limit reached",
    "referral link": "Referral Link",
    "referral reward": "Referral Reward",
    "referral_code_apply": "Apply referral code",
    "referral_code_email_required": "Email needed to apply referral code",
    "referral_code_enter": "Enter referral code or referral url",
    "referral_code_minimum_order": "Need to order {spendRequirementAmount} to be able to apply promo code",
    "referral_code_recipient_benefit_amount": "Receive {rewardAmount} discount as promo code",
    "referral_code_recipient_benefit_rate": "Receive {rewardRate}% discount as promo code",
    "referral_code_recipient_benefit_rate_onsale": "Receive {rewardRate}% discount ({rewardRateOnSale}% discount for onsale items) as promo code",
    "referral_code_refer_friends": "Refer friends",
    "referral_code_referred_by": "You have been referred by {name}",
    "referral_code_referrer_benefit_amount_as_points": "Receive {earnAmount} as reward points",
    "referral_code_referrer_benefit_amount_as_promo_code": "Receive {earnAmount} as promo code",
    "referral_code_referrer_benefit_for_every_success_order": "for every succesful order using your referral code",
    "referral_code_referrer_benefit_rate_as_points": "Receive {earnRate}% from your friends total order as reward points",
    "referral_code_referrer_benefit_rate_as_promo_code": "Receive {earnRate}% discount as promo code",
    "referral_code_share_by_email": "Share referral code by email",
    "referral_code_share_by_link": "Share referral code by link",
    "referral_reward_recipient": "Recipient",
    "referral_reward_referrer": "Referrer",
    "refresh page error message": "Error occured, please refresh page. If the issue persists please contact us",
    "region": "Region",
    "register": "Signup",
    "related": "Related",
    "remaining choices": "Remaining choices",
    "remaining qty": "Remaining quantity",
    "rememberCreditCard": "Remember credit card",
    "remove item": "Remove item",
    "renew credit card": "Please renew your credit card information",
    "required": "Required",
    "resend verification email": "Resend verification email",
    "reset password": "Reset password",
    "resetFilters": "Reset filters",
    "reveal": "Reveal",
    "reward event": "Reward event",
    "reward history": "Reward history",
    "reward point cannot be used with promo code": "Reward points cannot be used with promo code",
    "reward points available after registration": "Reward points are recorded, but will only be available after registration",
    "reward points earned": "Reward points earned",
    "rewardSpendDifference": "Spend an additional {spendAmount} to qualify to spend your reward points",
    "reward_adjustment": "Adjustment",
    "reward_adjustment_points": "Adjustment points",
    "reward_birthday": "Birthday",
    "reward_birthday_points": "Bithday points",
    "reward_current_points": "Current points",
    "reward_earned_points": "Earned points",
    "reward_expire_soon": "Expire soon",
    "reward_no_data": "No reward history at this periods",
    "reward_opening": "Opening Points",
    "reward_opening_points": "Opening points",
    "reward_order": "Order",
    "reward_points": "Reward points",
    "reward_promotion": "Promotion",
    "reward_promotion_points": "Promotion points",
    "reward_referral": "Referral",
    "reward_referral_points": "Referral points",
    "reward_return": "Return",
    "reward_returns": "Returns",
    "reward_signup": "Sign up",
    "reward_signup_points": "Sign up points",
    "reward_spent_points": "Spent points",
    "reward_use_points_before_expired": "Don't forget to use your reward points before they expired!",
    "saturday": "Sat",
    "save": "Save",
    "search": "Search",
    "search by district": "Search by District",
    "search by store": "Search by Store",
    "search results": "Search Results",
    "select": "Select",
    "select below": "Select below",
    "select convenience store": "Select convenience store",
    "select periods": "Select periods",
    "selected": "Selected",
    "send": "Send",
    "send email": "Send email",
    "send message": "Send Message",
    "send to different address": "Send to different address",
    "sevenEleven": "7-11",
    "shipping details": "Shipping details",
    "shipping fee": "Shipping fee",
    "shipping fees is quoted later": "Shipping fees is quoted later",
    "shipping fees will be calculated after your address is selected": "Shipping fees will be calculated after your address is selected",
    "shippingFeeDifference": "Spend an additional {amount} to qualify for free shipping",
    "shippingFeeDifferenceQty": "Buy {amount} more items to qualify for free shipping",
    "shippingFeeDifferenceValue": "Spend an additional {amount} to qualify for free shipping",
    "shippingFeeDifferenceWeight": "Buy {amount} more items weights to qualify for free shipping",
    "shop": "Shop",
    "shop_dot_com_message_default": "提醒您，以下都是詐騙：訂單設錯、訂單金額有誤、設成分期每月扣款、重覆訂購多筆、宅配或超商出錯、簽收單簽成簽帳單、條碼刷錯、重覆扣款。歹徒會以更改訂單要求退費給您為由，要求您操作 ATM，這些都是詐騙手法。若遇可疑來電，隨時可撥打 165 反詐騙諮詢專線。",
    "shop_dot_com_message_header": "Message From SHOP.COM (TW)",
    "show more": "Show More",
    "show reward history": "Show reward history",
    "signupByBigOrdr": "Sign up by BigOrdr",
    "single time purchase": "Single time purchase",
    "sitemap": "Site Map",
    "sold out": "Sold out",
    "soldOut": "Sold out",
    "sorry, no products found": "Sorry, no products found",
    "sorting": "Sorting",
    "specifications": "specifications",
    "start": "Start",
    "start game": "Start Game",
    "status": "Status",
    "store location": "Store location",
    "store location pick up": "Store location pick up",
    "store pick up": "Store pick up",
    "storeId": "Store ID",
    "storeLocationPickup": "Store location pick up",
    "storeName": "Store Name",
    "street or address": "Street or Address",
    "submit": "Submit",
    "subscribe": "Subscribe",
    "subscribe mailing list": "You have successfully re-subscribed, from now on, you will receive our emails!",
    "subtotal": "Subtotal",
    "successfully submission": "Thank you! Your form has been successfully submitted.",
    "sunday": "Sun",
    "support": "Support",
    "surcharge": "Surcharge",
    "tap the right side to skip": "Tap the right side to skip",
    "tax number": "Tax number",
    "tax number may be incorrect": "Tax number may be incorrect",
    "tax number short": "TX No.",
    "telephone": "Telephone",
    "terms and conditions": "Terms and Conditions",
    "the bank may be under maintenance": "The bank may be under maintenance Try again later.",
    "the merchant may have changed this product": "The merchant may have changed this product, please re-select and add to cart again.",
    "threw an error when running default method": "Invalid",
    "thursday": "Thu",
    "to": "To",
    "total": "Total",
    "total periods": "Total Periods",
    "track waybill": "Track waybill",
    "transporter": "Transporter",
    "try again": "Try again",
    "tuesday": "Tue",
    "twEInvoice": "E-Invoice",
    "twEInvoiceCarrierId": "Carrier ID",
    "twEInvoiceCarrierId_b2b": "Tax number (8 digits of numbers)",
    "twEInvoiceCarrierId_citizenDigitalCertificate": "Carrier ID (16 capital alpha-numeric characters; 2 capital characters + 14 numbers)",
    "twEInvoiceCarrierId_mobile": "Carrier ID (\"/\" + 7 capital alpha-numeric characters)",
    "twEInvoiceCarrierType": "Carrier type",
    "twEInvoiceCarrierType_optional": "Carrier type (optional)",
    "tw_eInvoice_carrierType_b2b": "Business（營業人發票）",
    "tw_eInvoice_carrierType_b2c": "Personal（個人發票）",
    "tw_eInvoice_carrierType_mobile": "Mobile（手機條碼）",
    "tw_eInvoice_carrierType_person": "Natural person（自然人憑證）",
    "tw_eInvoice_carrier_gov_link": "財政部電子發票流程說明(Taiwan Only)",
    "tw_eInvoice_carrier_help": "*依法規定，個人發票一經開立，不得更改或改開公司戶發票。(Taiwan Only)",
    "tw_eInvoice_print": "列印電子發票(Taiwan Only)",
    "tw_eInvoice_upload": "預設會員載具(Taiwan Only)",
    "unlock": "Unlock",
    "unsubscribe": "Unsubscibe",
    "unsubscribe mailing list": "You have unsubscribed, and will not receive our emails",
    "update qty": "Update quantity",
    "use a different card": "Use a different card",
    "use default tax number": "Use default tax number",
    "use different delivery info": "Use a Different Delivery Info",
    "use id name when choosing convenience store pick up": "Please use the same name as your identification documents when you choose convenience store pick up",
    "use new credit card": "Enter New Credit Card on next page",
    "use stored card": "Use stored card",
    "valid_reward_points": "Valid reward points",
    "validation error": "{key}: Invalid",
    "validity date": "Validity date",
    "value is required": "Required",
    "view details": "View details",
    "view or leave messages": "View or leave messages",
    "virtual account bank code": "Virtual account bank code",
    "virtual account bank name": "Virtual account bank name",
    "virtual account expiry date": "Virtual account expiry date",
    "virtual account number": "Virtual account number",
    "virtual_account_generate_info": "Your unique virtual account number will be generated and displayed after checking out",
    "virtual_account_provider_bsp": "永豐商業銀行 SinoPac (807)",
    "virtual_account_provider_eSun": "E.SUN（玉山銀行） virtual account",
    "virtual_account_provider_ecPay": "ECPay（綠界科技） virtual account",
    "virtual_account_provider_payuni": "Payuni virtual account",
    "virtual_account_provider_ubot": "UBOT（聯邦銀行） virtual account (803)",
    "visa": "VISA",
    "waybill": "Waybill",
    "we currently do not ship to this region": "We currently do not ship to this region",
    "wednesday": "Wed",
    "you have already got a promo code": "You have already got a promo code",
    "you have already played the game": "You have already played the game!",
    "your last 5 digits of your account": "Your last 5 digits of your account",
    "your login link is no longer valid": "Your login link is no longer valid"
  };
  _exports.default = _default;
});