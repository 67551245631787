define("shop/pods/components/shop/checkout/details-page/component", ["exports", "ramda", "@ember/component", "@ember/service", "ember-concurrency", "@ember/object", "shop/mixins/checkout", "shop/utils/nventor"], function (_exports, R, _component, _service, _emberConcurrency, _object, _checkout, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _component.default.extend(_checkout.default, (_obj = {
    isPickup: '',
    pickupStoreAddress: '',
    ajax: (0, _service.inject)(),
    checkoutService: (0, _service.inject)('checkout'),
    googleService: (0, _service.inject)('google'),
    intl: (0, _service.inject)(),
    websiteService: (0, _service.inject)('website'),
    storage: (0, _service.inject)(),
    router: (0, _service.inject)(),
    members: (0, _service.inject)(),
    dateService: (0, _service.inject)('date'),
    tagName: '',
    error: '',
    surchargeError: '',
    successfulPayment: null,
    showAlternateAddressSelector: false,
    previousSelectedDeliveryMethod: null,
    showFormStep: false,

    init() {
      this._super(...arguments);

      _nventor.default.goToTop();

      this.setupUser.perform();
      const memberId = this.get('website.user._data.memberId');
      this.get('websiteService').get('logTask').perform({
        event: 'view',
        eventType: 'view',
        memberId
      });
    },

    didInsertElement() {
      var _this$shop, _this$shop$_data;

      this._super(...arguments);

      if (!this.get('website.shop.hasCartItems')) {
        this.router.transitionTo('shop');
      }

      const pickupLocations = ((_this$shop = this.shop) === null || _this$shop === void 0 ? void 0 : (_this$shop$_data = _this$shop._data) === null || _this$shop$_data === void 0 ? void 0 : _this$shop$_data.pickupLocations) || [];
      this.set('pickupLocations', pickupLocations);
      const pickupMethod = this.pickupMethod || 'homeDelivery';
      this.onSelectPickupMethod(pickupMethod);
    },

    deliveryDateOptionsPerZone: (0, _object.computed)('checkoutData.{expectedArrivalDatesSelection.[],storeLocationData.expectedDateZ}', 'pickupLocations.[]', 'storeLocation', 'pickupMethod', 'shop.shippingFeeData.combinedZones.[]', 'shop.expectedDateMethodLabel', function () {
      var _this$shop2, _this$shop2$shippingF;

      const pickupMethod = this.pickupMethod;
      const zones = ((_this$shop2 = this.shop) === null || _this$shop2 === void 0 ? void 0 : (_this$shop2$shippingF = _this$shop2.shippingFeeData) === null || _this$shop2$shippingF === void 0 ? void 0 : _this$shop2$shippingF.combinedZones) || [];
      const checkoutData = this.checkoutData;

      if (pickupMethod === 'storeLocationPickup') {
        const pickupLocations = this.pickupLocations || [];

        if (_nventor.default.isNilOrEmpty(pickupLocations)) {
          return [];
        }

        const storeLocation = this.storeLocation;

        if (!storeLocation) {
          return [];
        }

        const userStoreLocationData = R.find(R.propEq('address', storeLocation))(pickupLocations);
        const deliveryDateOptions = (userStoreLocationData === null || userStoreLocationData === void 0 ? void 0 : userStoreLocationData.deliveryDateOptions) || {}; // set disallowedArrivalDays = disallowedDispatchDays , due to store colse day should be exclude

        deliveryDateOptions.disallowedArrivalDays = deliveryDateOptions.disallowedDispatchDays || [];
        const expectedDateZ = R.path(['storeLocationData', 'expectedDateZ'])(checkoutData) || '';

        if (_nventor.default.isNilOrEmpty(userStoreLocationData === null || userStoreLocationData === void 0 ? void 0 : userStoreLocationData.deliveryDateOptions)) {
          return [];
        }

        return [_object.default.create({
          type: 'pickup',
          priorityZone: 'storePickup',
          // used for div id purposes only
          deliveryDateOptions,
          expectedDateZ,
          label: 'choose expected pickup date',
          maxDate: deliveryDateOptions.lastestArrivalDateZ,
          minDate: deliveryDateOptions.earliestArrivalDateZ
        })];
      } // groupby transporter


      const uniqueZones = R.pipe(R.reject(zone => {
        return _nventor.default.isNilOrEmpty(zone === null || zone === void 0 ? void 0 : zone.deliveryDateOptions);
      }), R.groupBy(zone => {
        return zone === null || zone === void 0 ? void 0 : zone.priorityZone;
      }), R.mapObjIndexed((zonesByTransporter, key) => {
        var _this$shop3;

        const zoneIds = R.pipe(R.pluck('zoneId'), R.reject(_nventor.default.isNilOrEmpty))(zonesByTransporter);
        const deliveryDateOptions = R.path([0, 'deliveryDateOptions'])(zonesByTransporter);
        const priorityZone = R.path([0, 'priorityZone'])(zonesByTransporter);
        const defaultExpectedDateZ = '';
        const expectedDateZ = R.pipe(R.propOr([], 'expectedArrivalDatesSelection'), R.find(R.propEq('priorityZone', priorityZone)), R.propOr(defaultExpectedDateZ, 'expectedDateZ'))(checkoutData);
        const expectedDateMethodLabel = (this === null || this === void 0 ? void 0 : (_this$shop3 = this.shop) === null || _this$shop3 === void 0 ? void 0 : _this$shop3.expectedDateMethodLabel) || '';
        let maxDate = '';
        let minDate = '';

        if (expectedDateMethodLabel === 'expectedDispatchDate') {
          maxDate = deliveryDateOptions.latestDispatchDateZ;
          minDate = deliveryDateOptions.earliestDispatchDateZ;
        } else {
          maxDate = deliveryDateOptions.lastestArrivalDateZ;
          minDate = deliveryDateOptions.earliestArrivalDateZ;
        }

        return _object.default.create({
          type: 'zone',
          priorityZone,
          // used for div id purposes only,
          zoneIds,
          deliveryDateOptions,
          expectedDateZ,
          label: `choose ${expectedDateMethodLabel}`,
          maxDate,
          minDate
        });
      }), R.values, R.flatten)(zones);
      return uniqueZones;
    }),
    pickupMethod: (0, _object.computed)('shop._data.pickupMethods.[]', 'shop._data.pickupLocations.[]', 'checkoutData.pickupMethod', function () {
      var _this$shop4, _this$shop4$_data, _this$shop5, _this$shop5$_data, _this$shop6, _this$shop6$_data, _this$checkoutData, _this$checkoutData2, _this$checkoutData2$c;

      const allowShipping = R.propOr(true, 'allowShipping')(this);
      const allowPickup = ((_this$shop4 = this.shop) === null || _this$shop4 === void 0 ? void 0 : (_this$shop4$_data = _this$shop4._data) === null || _this$shop4$_data === void 0 ? void 0 : _this$shop4$_data.allowPickup) || false;
      const pickupLocations = ((_this$shop5 = this.shop) === null || _this$shop5 === void 0 ? void 0 : (_this$shop5$_data = _this$shop5._data) === null || _this$shop5$_data === void 0 ? void 0 : _this$shop5$_data.pickupLocations) || [];
      const pickupMethods = ((_this$shop6 = this.shop) === null || _this$shop6 === void 0 ? void 0 : (_this$shop6$_data = _this$shop6._data) === null || _this$shop6$_data === void 0 ? void 0 : _this$shop6$_data.pickupMethods) || [];
      let pickupMethod = (_this$checkoutData = this.checkoutData) === null || _this$checkoutData === void 0 ? void 0 : _this$checkoutData.pickupMethod;
      const pickupType = (_this$checkoutData2 = this.checkoutData) === null || _this$checkoutData2 === void 0 ? void 0 : (_this$checkoutData2$c = _this$checkoutData2.checkoutAddressData) === null || _this$checkoutData2$c === void 0 ? void 0 : _this$checkoutData2$c.type;

      if ((pickupMethod === 'sevenEleven' || pickupMethod === 'familyMart' || pickupMethod === 'convenienceStore') && pickupType === 'convenienceStore') {
        var _this$checkoutData3, _this$checkoutData3$c;

        pickupMethod = ((_this$checkoutData3 = this.checkoutData) === null || _this$checkoutData3 === void 0 ? void 0 : (_this$checkoutData3$c = _this$checkoutData3.checkoutAddressData) === null || _this$checkoutData3$c === void 0 ? void 0 : _this$checkoutData3$c.brand) || pickupMethod;
      }

      const isPickupMethodAllowed = R.any(R.propEq('value', pickupMethod))(pickupMethods);

      if (!isPickupMethodAllowed) {
        var _pickupMethods$;

        return pickupMethods === null || pickupMethods === void 0 ? void 0 : (_pickupMethods$ = pickupMethods[0]) === null || _pickupMethods$ === void 0 ? void 0 : _pickupMethods$.value;
      }

      if (allowShipping && pickupMethod === 'homeDelivery') {
        return 'homeDelivery';
      }

      if (allowPickup) {
        if (pickupMethod === 'storeLocationPickup' && pickupLocations.length !== 0) {
          return 'storeLocationPickup';
        }
      }

      return pickupMethod;
    }),
    deliveryTimeLists: (0, _object.computed)(function () {
      return [_object.default.create({
        label: 'noon',
        value: 'noon'
      }), _object.default.create({
        label: 'night',
        value: 'night'
      })];
    }),
    disabledNextButton: (0, _object.computed)('checkoutData.storeId', 'checkoutData.convenienceStoreData.{brand,storeId}', function () {
      var _this$checkoutData4, _this$checkoutData5, _this$checkoutData5$c;

      const convenienceStoreData = this === null || this === void 0 ? void 0 : (_this$checkoutData4 = this.checkoutData) === null || _this$checkoutData4 === void 0 ? void 0 : _this$checkoutData4.convenienceStoreData;

      if (!(convenienceStoreData !== null && convenienceStoreData !== void 0 && convenienceStoreData.brand)) {
        return false;
      }

      if ((_this$checkoutData5 = this.checkoutData) !== null && _this$checkoutData5 !== void 0 && (_this$checkoutData5$c = _this$checkoutData5.convenienceStoreData) !== null && _this$checkoutData5$c !== void 0 && _this$checkoutData5$c.storeId) {
        return false;
      }

      return true;
    }),
    isAddressEditable: (0, _object.computed)('user._data.hasAddresses', 'checkoutData.useAlternateAddress', function () {
      if (this.get('checkoutData.useAlternateAddress')) {
        return true;
      }

      if (!this.get('user._data.hasAddresses')) {
        return true;
      }

      return false;
    }),
    hasSurchargeOptions: (0, _object.computed)('shop.{updated,shippingFeeData.combinedZones.[]}', function () {
      const combinedZones = this.get('shop.shippingFeeData.combinedZones') || [];

      if (R.isEmpty(combinedZones)) {
        return false;
      }

      const hasSurchargeOptions = R.reduce((acc, zone) => {
        if (acc) {
          return true;
        }

        const surcharges = R.propOr([], 'surcharges')(zone);

        if (!R.isEmpty(surcharges)) {
          return true;
        }

        return false;
      }, false)(combinedZones);

      if (!hasSurchargeOptions) {
        return false;
      }

      const hasSurchargeWhenFreeShipping = R.find(R.propEq('hasSurchargeWhenFreeShipping', true))(combinedZones);

      if (hasSurchargeWhenFreeShipping) {
        return true;
      }

      const shippingFees = R.reduce((acc, zone) => {
        const rate = parseInt(R.propOr([], 'rate')(zone)) || 0;
        acc = acc + rate;
        return acc;
      }, 0)(combinedZones);

      if (shippingFees > 0) {
        return true;
      }

      return false;
    }),
    combinedZonesWithSelectedSurchargeIds: (0, _object.computed)('shop.shippingFeeData.combinedZones.[]', 'hasSurchargeOptions', 'checkoutData', function () {
      var _this$shop7, _this$shop7$shippingF, _this$checkoutData6, _this$checkoutData6$c;

      const combinedZones = ((_this$shop7 = this.shop) === null || _this$shop7 === void 0 ? void 0 : (_this$shop7$shippingF = _this$shop7.shippingFeeData) === null || _this$shop7$shippingF === void 0 ? void 0 : _this$shop7$shippingF.combinedZones) || [];
      const selectedSurchargeIds = ((_this$checkoutData6 = this.checkoutData) === null || _this$checkoutData6 === void 0 ? void 0 : (_this$checkoutData6$c = _this$checkoutData6.checkoutAddressData) === null || _this$checkoutData6$c === void 0 ? void 0 : _this$checkoutData6$c.surchargeId) || [];
      return R.map(zone => {
        zone.selectedSurchargeId = R.pipe(R.propOr([], 'surcharges'), R.find(surcharge => {
          return R.includes(surcharge.surchargeId)(selectedSurchargeIds);
        }), R.propOr('', 'surchargeId'))(zone);
        return zone;
      })(combinedZones);
    }),
    showZoneNotFoundError: (0, _object.computed)('pickupMethod', 'shop.shippingFeeData.zoneNotFound', function () {
      var _shop$shippingFeeData;

      if (this.pickupMethod === 'storeLocationPickup') {
        return false;
      }

      const shop = (this === null || this === void 0 ? void 0 : this.shop) || {};

      if (shop !== null && shop !== void 0 && (_shop$shippingFeeData = shop.shippingFeeData) !== null && _shop$shippingFeeData !== void 0 && _shop$shippingFeeData.zoneNotFound) {
        return true;
      }

      if (!(shop !== null && shop !== void 0 && shop.isMinShippingRateReached)) {
        return true;
      }

      if (!this.hasUserAddressData) {
        return false;
      }

      return false;
    }),
    checkoutForm: (0, _object.computed)('shop._data.{checkoutFormKey,flows}', function () {
      var _shop$_data, _shop$_data2;

      const shop = (this === null || this === void 0 ? void 0 : this.shop) || {};
      const campaignFlows = (shop === null || shop === void 0 ? void 0 : (_shop$_data = shop._data) === null || _shop$_data === void 0 ? void 0 : _shop$_data.flows) || [];
      const checkoutFormKey = (shop === null || shop === void 0 ? void 0 : (_shop$_data2 = shop._data) === null || _shop$_data2 === void 0 ? void 0 : _shop$_data2.checkoutFormKey) || '';

      if (R.isEmpty(campaignFlows)) {
        return {};
      }

      if (!checkoutFormKey) {
        return {};
      }

      const checkoutForm = R.pipe(R.pathOr([], ['_data', 'flows']), R.find(R.propEq('_key', checkoutFormKey)))(shop);

      if (R.isEmpty(checkoutForm)) {
        return {};
      }

      return checkoutForm;
    }),
    nextTask: (0, _emberConcurrency.task)(function* (onBefore, e) {
      if (R.is(Function, onBefore === null || onBefore === void 0 ? void 0 : onBefore.perform)) {
        const successful = yield onBefore.perform(e);

        if (!successful) {
          return false;
        }
      }

      const website = this.website;
      const shop = this.shop;
      const user = website.get('user');
      const checkoutData = this.checkoutData;
      checkoutData.set('pickupMethod', this.pickupMethod); // @note: temp fix only

      this.set('surchargeError', false);
      const userData = user.serialize();

      try {
        var _this$checkoutData7;

        const isConvenienceStore = checkoutData.get('isConvenienceStore') || false;
        const convenienceStoreData = checkoutData.get('convenienceStoreData') || {};
        const country = R.pathOr('', ['checkoutAddressData', 'country'], checkoutData);
        const countryCode = R.pathOr('', ['checkoutAddressData', 'countryCode'], checkoutData);

        if (user.get('isGuest')) {
          const countries = this.allowedCountries;
          const defaultCountry = user.getDefaultAllowedCountry({
            countries,
            countryCode
          });
          userData.country = country || R.prop('name')(defaultCountry);
          userData.countryCode = countryCode || R.prop('code')(defaultCountry);
        }

        const pickupMethod = this === null || this === void 0 ? void 0 : (_this$checkoutData7 = this.checkoutData) === null || _this$checkoutData7 === void 0 ? void 0 : _this$checkoutData7.pickupMethod;

        if (pickupMethod === 'storeLocationPickup') {
          if (!userData.country) {
            userData.country = 'Taiwan';
            userData.countryCode = 'TW';
          }
        }

        const isPickup = checkoutData.get('isPickup') || '';

        if (shop !== null && shop !== void 0 && shop.isVirtualOnly) {
          // dont validate address
          user.validate('checkoutWithoutCountry', userData);
        } else if (this.hasAddress && !isPickup) {
          user.validate('checkout', userData);
          const checkoutAddressData = checkoutData.get('checkoutAddressData'); // user.validate('address', checkoutAddressData)

          const pickupMethod = checkoutData.get('pickupMethod') || '';

          if (!pickupMethod || pickupMethod === 'homeDelivery') {
            if (checkoutAddressData.hasPostCode) {
              user.validate('address', checkoutAddressData);
            } else {
              user.validate('addressPostCodeNotRequired', checkoutAddressData);
            }
          } else if (pickupMethod === 'familyMart' || pickupMethod === 'sevenEleven') {
            user.validate('convenienceStore', convenienceStoreData);
          }

          const hasSurchargeOptions = this.get('hasSurchargeOptions') || false;

          if (hasSurchargeOptions) {
            const combinedZones = this.get('shop.shippingFeeData.combinedZones') || [];
            const totalRequiredSurchargeIds = R.pipe(R.filter(zone => {
              return _nventor.default.isNotNilOrEmpty(zone.surcharges);
            }), R.length)(combinedZones);
            const allowedSurchargeIds = R.pipe(R.map(R.pipe(R.propOr([], 'surcharges'), R.pluck('surchargeId'))), R.flatten, R.filter(surchargeId => {
              return _nventor.default.isNotNilOrEmpty(surchargeId);
            }))(combinedZones);
            let surchargeIds = (checkoutAddressData === null || checkoutAddressData === void 0 ? void 0 : checkoutAddressData.surchargeId) || [];
            surchargeIds = R.filter(surchargeId => {
              return R.includes(surchargeId)(allowedSurchargeIds);
            })(surchargeIds);

            if (surchargeIds.length < totalRequiredSurchargeIds) {
              this.set('surchargeError', true);
              return false;
            }
          }

          const countryData = yield this.websiteService.get('getCountryDataTask').perform({
            countryCode,
            country
          });
          const addressBuilder = R.prop('addressBuilder', countryData);

          if (!isConvenienceStore) {
            checkoutData.set('address', R.pipe(R.map(key => {
              return R.propOr(key, key)(checkoutData.checkoutAddressData);
            }), R.join(''))(addressBuilder));
          }

          if (checkoutData.useAlternateAddress) {
            const alternateAddressData = checkoutData.get('alternateAddressData');
            alternateAddressData.countryCode = countryCode;
            user.validate('alternateAddress', {
              alternateAddressData
            });
          } else {
            checkoutData.set('alternateAddressData', {
              name: '',
              telephone: ''
            });
          }

          if (user.get('isMember') && !userData.hasAddresses && !checkoutData.useAlternateAddress && !checkoutData.get('isPickup') && !isConvenienceStore) {
            // const addresses = user.get('_data.addresses') || []
            // addresses.pushObject(userData.addressData)
            user.set('_data.addresses', [checkoutData.addressData]);
            yield this.membersService.get('updateMemberAddressesTask').perform(website, user);
          }
        } else {
          user.validate('checkoutWithoutCountry', userData);
          const pickupAddress = checkoutData.get('isPickup');
          checkoutData.set('address', pickupAddress);
          checkoutData.set('country', '');
          checkoutData.set('countryCode', '');
          const storeLocationData = checkoutData.get('storeLocationData') || {};

          if (isPickup) {
            checkoutData.set('storeLocationData', storeLocationData);
          }
        }

        checkoutData.set('taxNumber', user.get('_data.taxNumber'));
        checkoutData.set('companyName', user.get('_data.companyName'));
        checkoutData.set('twEInvoiceCarrierType', user.get('_data.twEInvoiceCarrierType'));
        checkoutData.set('twEInvoiceCarrierId', user.get('_data.twEInvoiceCarrierId'));
        checkoutData.set('twEInvoicePrint', user.get('_data.twEInvoicePrint'));
        user.persistUserDataToStorage(userData);
        this.storage.setValueWithShopKey('checkoutData', checkoutData); // GoogleTrack Add Shipping Info

        this.googleService.checkoutStep({
          step: 2,
          shop: this.shop
        });
        return this.router.transitionTo('shop.checkout.payment');
      } catch (e) {
        yield this.websiteService.get('logErrorTask').perform({
          error: e,
          checkoutData: this.checkoutData,
          automations: this.shop.getCartAutomationsData(),
          cart: this.shop.getCartItemsData()
        });
        this.websiteService.setErrors(e, {
          errorMessage: 'error occurred, please contact us'
        });
      }
    }),

    selectExpectedDate(zone, dateString) {
      var _this$checkoutData8;

      const todayDateString = this.dateService.getMoment(dateString).format('YYYY-MM-DD');

      if (dateString) {
        dateString = this.dateService.getStartOfDayZ(dateString);
      }

      const pickupMethod = this.pickupMethod;

      if (pickupMethod === 'storeLocationPickup') {
        this.checkoutData.set('storeLocationData.expectedDateZ', dateString);
        this.checkoutData.set('storeLocationData.expectedDateString', todayDateString);
        return;
      }

      const expectedArrivalDatesSelection = ((_this$checkoutData8 = this.checkoutData) === null || _this$checkoutData8 === void 0 ? void 0 : _this$checkoutData8.expectedArrivalDatesSelection) || [];
      (0, _object.set)(zone, 'expectedDateZ', dateString);
      const foundIndex = R.findIndex(R.propEq('priorityZone', (zone === null || zone === void 0 ? void 0 : zone.priorityZone) || ''))(expectedArrivalDatesSelection); // @NOTE: is this not just setting expectedDateZ and expectedDateString on zone? no need to find?

      if (foundIndex === -1) {
        expectedArrivalDatesSelection.pushObject(zone);
        (0, _object.set)(expectedArrivalDatesSelection[0], 'expectedDateZ', dateString); // this is no longer used on the api. only used to display date picker on shop

        (0, _object.set)(expectedArrivalDatesSelection[0], 'expectedDateString', todayDateString);
      } else {
        (0, _object.set)(expectedArrivalDatesSelection[foundIndex], 'expectedDateZ', dateString); // this is no longer used on the api. only used to display date picker on shop

        (0, _object.set)(expectedArrivalDatesSelection[foundIndex], 'expectedDateString', todayDateString);
      }

      (0, _object.set)(this.checkoutData, 'expectedArrivalDatesSelection', expectedArrivalDatesSelection);
    },

    onSelectPickupMethod(pickupMethod) {
      var _checkoutData$checkou;

      let clearSelection = true;
      const checkoutData = this.checkoutData;

      if (!this.previousSelectedDeliveryMethod && pickupMethod !== this.previousSelectedDeliveryMethod) {
        clearSelection = false;
      }

      if (checkoutData !== null && checkoutData !== void 0 && (_checkoutData$checkou = checkoutData.checkoutAddressData) !== null && _checkoutData$checkou !== void 0 && _checkoutData$checkou.convenienceStoreAddress && pickupMethod !== 'sevenEleven' && pickupMethod !== 'familyMart') {
        clearSelection = true;
      }

      const user = this.user;

      if (clearSelection) {
        (0, _object.set)(checkoutData, 'convenienceStoreData', {});
        (0, _object.set)(checkoutData, 'storeAddress', '');
        (0, _object.set)(checkoutData, 'address', '');
        (0, _object.set)(checkoutData, 'region', '');
        (0, _object.set)(checkoutData, 'district', '');
        (0, _object.set)(checkoutData, 'postCode', '');
        (0, _object.set)(checkoutData, 'storeId', '');
        (0, _object.set)(checkoutData, 'storeName', '');
        (0, _object.set)(checkoutData, 'convenienceStoreAddress', '');
        (0, _object.set)(checkoutData, 'convenienceStoresList', []);
        (0, _object.set)(checkoutData, 'convenienceStoreSelected', []);
        (0, _object.set)(user, 'convenienceStoreData', {});
        (0, _object.set)(checkoutData, 'convenienceStoresCity', '');
        (0, _object.set)(checkoutData, 'convenienceStoresArea', '');
        (0, _object.set)(checkoutData, 'storeId', '');
        (0, _object.set)(checkoutData, 'storeName', '');
        (0, _object.set)(checkoutData, 'addressData.brand', '');
        (0, _object.set)(checkoutData, 'addressData.storeId', '');
        (0, _object.set)(checkoutData, 'addressData.storeAddress', '');
        (0, _object.set)(checkoutData, 'addressData.storeName', '');
        (0, _object.set)(checkoutData, 'addressData.type', '');
        (0, _object.set)(checkoutData, 'addressData.convenienceStoreAddress', '');
        (0, _object.set)(checkoutData, 'checkoutAddressData.convenienceStoreAddress', '');
        (0, _object.set)(checkoutData, 'checkoutAddressData.brand', '');
        (0, _object.set)(checkoutData, 'checkoutAddressData.storeId', '');
        (0, _object.set)(checkoutData, 'checkoutAddressData.storeAddress', '');
        (0, _object.set)(checkoutData, 'checkoutAddressData.storeName', '');
        (0, _object.set)(checkoutData, 'checkoutAddressData.type', '');
        (0, _object.set)(checkoutData, 'type', '');
        (0, _object.set)(checkoutData, 'brand', '');

        if (!this.website.isLoggedIn) {
          (0, _object.set)(checkoutData, 'addressData.region', '');
          (0, _object.set)(checkoutData, 'addressData.district', '');
          (0, _object.set)(checkoutData, 'addressData.postCode', '');
          (0, _object.set)(checkoutData, 'addressData.street', '');
          (0, _object.set)(checkoutData, 'addressData.address', '');
          (0, _object.set)(checkoutData, 'checkoutAddressData.region', '');
          (0, _object.set)(checkoutData, 'checkoutAddressData.district', '');
          (0, _object.set)(checkoutData, 'checkoutAddressData.postCode', '');
          (0, _object.set)(checkoutData, 'checkoutAddressData.street', '');
          (0, _object.set)(checkoutData, 'checkoutAddressData.address', '');
        }
      }

      this.set('previousSelectedDeliveryMethod', pickupMethod);
      checkoutData.set('isPickup', '');
      checkoutData.set('isConvenienceStore', false);
      checkoutData.set('pickupMethod', pickupMethod);

      if (pickupMethod === 'storeLocationPickup') {
        checkoutData.set('pickupMethod', pickupMethod);
        checkoutData.set('isConvenienceStore', false);
        checkoutData.set('useAlternateAddress', false);
        checkoutData.set('expectedArrivalDatesSelection', []); // clear expectedArrivalDatesSelection

        const pickupLocations = this.pickupLocations || [];
        let storeLocationSelected = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.storeLocationSelected) || '';

        if (R.length(pickupLocations) === 1) {
          storeLocationSelected = pickupLocations[0].address;
          this.onSelectStoreLocation(storeLocationSelected);
        } else if (!R.any(R.propEq('address', ''))(pickupLocations)) {
          pickupLocations.unshift({
            label: this.intl.t('please select'),
            address: ''
          });
          storeLocationSelected = '';
        }

        if (R.any(R.propEq('address', storeLocationSelected))(pickupLocations)) {
          this.onSelectStoreLocation(storeLocationSelected);
        } else {
          this.onSelectStoreLocation('');
        }
      } else if (pickupMethod === 'sevenEleven' || pickupMethod === 'familyMart') {
        checkoutData.set('isConvenienceStore', true);
        checkoutData.set('useAlternateAddress', false);
        user.setData('hasAddress', false);
        const convenienceStoreSelected = (checkoutData === null || checkoutData === void 0 ? void 0 : checkoutData.convenienceStoreSelected) || [];
        const convenienceStoreData = R.pipe(R.find(R.propEq('pickupMethod', pickupMethod)), R.propOr({}, 'convenienceStore'))(convenienceStoreSelected);
        convenienceStoreData.brand = pickupMethod;

        if (_nventor.default.isNotNilOrEmpty(convenienceStoreData)) {
          const updatedConvenienceStore = R.pipe(R.propOr({}, 'convenienceStoreData'), R.mergeRight(convenienceStoreData))(checkoutData);
          (0, _object.set)(checkoutData, 'convenienceStoreData', updatedConvenienceStore);
        }
      }

      this.shop.set('checkoutData', checkoutData);
      const shippingFeeData = this.shop.get('shippingFeeData');

      if (shippingFeeData) {
        this.shop.set('shippingFeeData.zoneNotFound', null);
      }

      this.storage.setValueWithShopKey('checkoutData', checkoutData);
      this.set('checkoutData', checkoutData);

      if ((!checkoutData.storeId || !checkoutData.storeName) && !(pickupMethod === 'homeDelivery')) {
        return;
      }

      checkoutData.forceValidation = true;
      this.validateCartItemsTask.perform(checkoutData);
    },

    validateCartItemsTask: (0, _emberConcurrency.task)(function* (checkoutData = {}) {
      var _this$shopService, _this$shopService$val, _this$shopService$val2;

      yield (_this$shopService = this.shopService) === null || _this$shopService === void 0 ? void 0 : (_this$shopService$val = _this$shopService.validateCartItemsTask) === null || _this$shopService$val === void 0 ? void 0 : (_this$shopService$val2 = _this$shopService$val.perform) === null || _this$shopService$val2 === void 0 ? void 0 : _this$shopService$val2.call(_this$shopService$val, {
        shop: this.shop,
        checkoutData,
        user: this.user,
        hasAddress: true
      }); // set checkoutData to current selected address location

      this.storage.setValueWithShopKey('checkoutData', checkoutData);
    }).restartable(),

    onSelectStoreLocation(store = '') {
      const checkoutData = this.checkoutData;
      const pickupLocations = this.pickupLocations || []; // checkoutData.set('storeLocationData.expectedDateZ', '')

      if (store) {
        this.set('storeLocation', store);
        const storeLocation = this.storeLocation;
        const userStoreLocationData = R.find(R.propEq('address', storeLocation))(pickupLocations);

        if (_nventor.default.isNotNilOrEmpty(userStoreLocationData)) {
          if ((userStoreLocationData === null || userStoreLocationData === void 0 ? void 0 : userStoreLocationData.label) === storeLocation) {
            checkoutData.set('isPickup', storeLocation);
          } else {
            checkoutData.set('isPickup', `(${userStoreLocationData === null || userStoreLocationData === void 0 ? void 0 : userStoreLocationData.label})${storeLocation}`);
          }

          checkoutData.set('storeLocationData', userStoreLocationData);
          checkoutData.set('storeLocationSelected', storeLocation);
        }
      } else {
        this.set('storeLocation', '');
        checkoutData.set('isPickup', '');
        checkoutData.set('storeLocationData', {});
        checkoutData.set('storeLocationSelected', '');
      }
    },

    isAddressRequired: (0, _object.computed)('shop.{updated,isVirtualOnly}', function () {
      const shop = this.shop;

      if (shop !== null && shop !== void 0 && shop.isVirtualOnly) {
        return false;
      }

      return true;
    }),

    validateOnSelectStoreLocation(store = '') {
      var _this$shopService2, _this$shopService2$va, _this$shopService2$va2;

      this.onSelectStoreLocation(store);
      (_this$shopService2 = this.shopService) === null || _this$shopService2 === void 0 ? void 0 : (_this$shopService2$va = _this$shopService2.validateCartItemsTask) === null || _this$shopService2$va === void 0 ? void 0 : (_this$shopService2$va2 = _this$shopService2$va.perform) === null || _this$shopService2$va2 === void 0 ? void 0 : _this$shopService2$va2.call(_this$shopService2$va, {
        shop: this.shop,
        checkoutData: this.checkoutData,
        user: this.user,
        hasAddress: true,
        forceValidation: true
      });
    },

    previous() {
      var _this$websiteService, _this$websiteService$;

      return this.router.transitionTo('shop.checkout.summary', (_this$websiteService = this.websiteService) === null || _this$websiteService === void 0 ? void 0 : (_this$websiteService$ = _this$websiteService.website) === null || _this$websiteService$ === void 0 ? void 0 : _this$websiteService$.shopUrlDisplay);
    },

    resetCheckoutAddressData() {
      const checkoutData = this.checkoutData;
      const user = this.user;
      this.setMembersAddress(user, checkoutData);
      this.storage.setValueWithShopKey('checkoutData', checkoutData);
      this.validateAddressTask.perform();
    },

    toggleShowFormStep() {
      const showFormStep = this.showFormStep;
      this.set('showFormStep', R.not(showFormStep));
    },

    actions: {
      resetAddressFromMember() {
        const checkoutData = this.checkoutData;
        const user = this.user;
        const allowedCountries = this.allowedCountries;
        const address = checkoutData.get('checkoutAddressData');
        user.resetDefaultAllowedCountry(address, allowedCountries);
        checkoutData.set('useAlternateAddress', true);
        this.set('showAlternateAddressSelector', true);
      },

      setSurchargeId(zone, surchargeId) {
        zone.tempSurchargeId = surchargeId;
        const combinedZones = this.get('shop.shippingFeeData.combinedZones') || [];
        const selectedSurchargeIds = R.pipe(R.pluck('tempSurchargeId'), R.reject(_nventor.default.isNilOrEmpty))(combinedZones) || [];
        this.checkoutData.checkoutAddressData.surchargeId = selectedSurchargeIds;
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "didInsertElement", [_object.action], Object.getOwnPropertyDescriptor(_obj, "didInsertElement"), _obj), _applyDecoratedDescriptor(_obj, "selectExpectedDate", [_object.action], Object.getOwnPropertyDescriptor(_obj, "selectExpectedDate"), _obj), _applyDecoratedDescriptor(_obj, "onSelectPickupMethod", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onSelectPickupMethod"), _obj), _applyDecoratedDescriptor(_obj, "onSelectStoreLocation", [_object.action], Object.getOwnPropertyDescriptor(_obj, "onSelectStoreLocation"), _obj), _applyDecoratedDescriptor(_obj, "validateOnSelectStoreLocation", [_object.action], Object.getOwnPropertyDescriptor(_obj, "validateOnSelectStoreLocation"), _obj), _applyDecoratedDescriptor(_obj, "previous", [_object.action], Object.getOwnPropertyDescriptor(_obj, "previous"), _obj), _applyDecoratedDescriptor(_obj, "resetCheckoutAddressData", [_object.action], Object.getOwnPropertyDescriptor(_obj, "resetCheckoutAddressData"), _obj), _applyDecoratedDescriptor(_obj, "toggleShowFormStep", [_object.action], Object.getOwnPropertyDescriptor(_obj, "toggleShowFormStep"), _obj)), _obj));

  _exports.default = _default;
});